import React, {useState} from 'react'
import './Navbar.css'
import ola from '../assets/ola.jpeg'
import folio from '../assets/folio.jpg'
import menu2 from '../assets/menu2.png'
import ImageClassification from './ImageClassification'
// import Snake
// import Avatar from '@mui/material/Avatar';
// import Chip from '@mui/material/Chip';
// import Olas from '../assets/Olas.png'
// import { UserAuth } from '../Context/AuthContext';
// import { UserAuth } from '../Context/AuthContext';
import { Link } from 'react-router-dom';

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const [isLogoDropdownOpen, setIsLogoDropdownOpen] = useState(false);

    // const { user, logOut } = UserAuth();
    // const toggleNavbar = () => {
    //     setIsOpen(!isOpen);

    // }
    const toggleLogoDropdown = () => {
        setIsLogoDropdownOpen(!isLogoDropdownOpen);
      };
    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    }
  return (
    <div class="Nav">
   <nav className="navbar">
      <div className="navbar-container">
        <div className={`navbar-logo ${isLogoDropdownOpen ? 'active' : ''}`} onClick={toggleLogoDropdown}>
          <a href="/"><img alt="pfp" className='portfolio-item color76' src={menu2} width="45px" height="40px" ></img> </a>
      
          
          <div className="dropdown-content color25"> 
            {/* <a href="/Logo.html" className='color22'>OlaFolio 3D</a> */}
     {/* <a href="/Tesseract.html" className='color28'> Tesseract </a>  */}
      {/* <a href="/ML_training.html" className='color28'>3D ML</a> */}
      <a href="/Abc.html" className='color28'>Abc</a>
      {/* <a href="/Neural.html" className='color28'>ANN</a> */}
      {/* <a href="/Research.html" className='color28'>Research</a> */}
      {/* <a href="/Statistics.html" className='color28'>Statistics</a> */}
      {/* <a href="/DeepLearning.html" className='color28'>DeepLearning</a> */}
      {/* <a href="/Robot.html" className='color28'> Agent Ola🎶</a> */}
     {/* <a href="/Vector4D.html" className='color28'>4D Vector</a>  */}
     {/* <a href="/Vector3D.html" className='color28'>3D Vector</a>  */}
     {/* <a href="/Cosmic.html" className='color28'>3D Cosmic</a>  */}
    <a href="/Air_Quality.html" className='color28'>Air Quality</a> 
    <a href="/Tesseract.html" className='color28'> Tesseract </a>
    <a href="/Energy_City.html" className='color28'>Smart City</a> 
     <a href="/Solar.html" className='color28'>Solar Panel </a> 
     {/* <a href="/Solar.html" className='color28'>3D Solar </a>  */}
     {/* <a href="/Galaxy" className='color28'>Galaxy</a>  */}
      {/* <a href="/ObjectDetector" className='color28'>Detector </a> */}
            {/* <a href="/Solar_panel.html" className='color66'>Drone Panel </a> */}
     {/* <a href="/Prediction.html" className='color28'>Image Classifier</a>
     <a href="/Face.html" className='color28'>Face Scanner</a> */}
      {/* <a href="/Tokenization" className='color28'>Tokenizer </a> */}
            <a href="/Music.html" className='color22'>Music 🎶</a>
      <a href="/Courses" className='color28'>StudyHub</a>
      {/* <a href="/Contact" className='color28'>Contact</a> */}
      {/* <a href="/Typing-game.html" className='color66'>Typing Game</a> */}
             {/* <a href="/Edu.html" className='color28'>Edu-Quest </a> */}
      {/* <a href="/Neurons.html" className='color28'>Neurons in 3D </a> */}
      {/* <a href="/DeepLearning.html" className='color28'> Intro to DL</a> */}
            {/* <a href="/Speech.html" className='color22'>Voice To Text</a> */}
    
            
            {/* <a href="/flight.html" className='color66'>Travel Predictor </a> */}
            
      {/* <a href="/Game.html" className='color28'>Space Invaders Game </a> */}
            {/* <a href="/Amazon" className='color45'>e-Store</a> */}
            {/* <a href="/Drone.html" className='color66'>🚉 Station</a> */}
            {/* <a href="/Edu.html" className='color66'>EduQuest</a> */}
            
            {/* <a href="/EVCharging_Station.html" className='color66'>Solar Panel </a> */}
            {/* <a href="/Solar_panel.html" className='color66'>Solar Panel </a> */}
      {/* <a href="/DNN.html" className='color28'>DNN</a> */}
      <a href="/Developer" className='color55'>Developer</a>
      {/* <a href="/Polygon.html" className='color28'>3D Polygon</a> */}
      {/* <a href="/Linear.html" className='color28'>Linear ALg Concepts</a> */}
      {/* <a href="/Classification.html" className='color28'>3D Classififcation</a> */}
      {/* <a href="/Extraction.html" className='color28'>Extraction</a> */}
      {/* <a href="/Gradient.html" className='color28'>Gradient</a> */}
      {/* <a href="/Merge" className='color28'>Merge </a> */}
      {/* <a href="/Bubble" className='color28'>Bubble </a> */}
      {/* <a href="/Insertion" className='color28'>Insertion</a> */}
      {/* <a href="/Selection" className='color28'>Selection </a> */}
      {/* <a href="/D3Chart" className='color28'> Emissions </a> */}
      {/* <a href="/Insertion" className='color28'>Insertion </a> */}
      {/* <a href="/BubbleChart" className='color28'>BubbleChart</a>   */}
      {/* <a href="/EnergyConsum" className='color28'>Energy </a>  */}
      {/* <a href="https://olafolio-electronics.netlify.app" className='color75'>AheadStore</a> */}
      {/* <a href="/Podcast" className='color33'>Podcast 🎙️</a> */}
      {/* <a href="/SnakeGame" className='color30'>C++ SnakeGame</a>  */}
      {/* <a href="/Learn" className='color42'>Learn </a> */}
      {/* <a href="/QuantumComp" className='color28'>Quantum </a> */}
      {/* <a href="/Skills" className='color10'>☞LLM.ai 🤖</a> */}
      {/* <a href="https://ola-we-da.netlify.app/" target="_blank" className='color27'>Weather</a> */}
      {/* <a href="https://ola-we-da.netlify.app/" target="_blank" className='color27'>☞Weather</a> */}
     
          </div>
        </div>
       
     
        <div className={`navbar-links ${isOpen ? 'active' : ''}`}>
         <h2><a href="/Developer" className='color136'>About</a></h2> 
          

         <h2><a href="/Courses" className='color34'>StudyHub</a></h2> 
         <h2>

         <a href="/ObjectDetector" className='color28'>Object Detector </a>
         </h2>
        </div>
        <div className="navbar-toggle" onClick={toggleNavbar}>
          <i className={`fas fa-${isOpen ? 'times' : 'bars'}`}></i>
        </div>
      </div>
    </nav>
    </div>
  )
}

export default Navbar